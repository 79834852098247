:root {
  --background: #ddd;
  --background2: #ccc;
  --primary: #4caf50;
}

html {
  background: var(--background);
}

body {
  display: flex;
  background: var(--background);
  justify-content: center;
}

#root {
  min-width: 100vw;
}

#outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.rotating {
  animation: rotate 1s forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.small {
  font-size: 0.75rem;
}

 ::-moz-selection, ::selection {
  color: var(--background);
  background: var(--primary);
}
